
import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-fullscreen.css";
import "lightgallery/css/lg-pager.css";
import "lightgallery/css/lg-autoplay.css";
import lightGallery from "lightgallery";
import g1 from "../Asset/Gallery/g1.png"
import g2 from "../Asset/Gallery/g2.webp"
import g12 from "../Asset/Gallery/g12.jpg"
import g3 from "../Asset/Gallery/g3.jpeg"
import g4 from "../Asset/Gallery/g4.jpeg"
import g5 from "../Asset/Gallery/g5.jpeg"
import g6 from "../Asset/Gallery/g6.jpeg"
import g7 from "../Asset/Gallery/g7.jpg"
import g8 from "../Asset/Gallery/g8.jpg"
import g9 from "../Asset/Gallery/g9.jpg"
import g10 from "../Asset/Gallery/g10.jpg"
import g11 from "../Asset/Gallery/g11.jpg"
import { MetaTags } from 'react-meta-tags';
const Gallery = () => {
    useEffect(() => {
        // Initialize lightGallery
        lightGallery(document.getElementById("image-gallery"), {
          mode: "lg-fade",
          thumbnail: true,
          zoom: false,
          autoplay: false,
        });
      }, []);
  return (
    <div>
        <MetaTags>
    <title>Best Beauty Services Salon in Andheri | Diamond Unisex Salon</title>
    <meta title=" Best Beauty Services Salon in Andheri | Diamond Unisex Salon" />
    <meta name="description" content=" Indulge in the Best Beauty Services at our Salon in Andheri. Our skilled professionals redefine elegance, offering a diverse range of services." />
    <meta name="keywords" content="Unisex Beauty Salon in Andheri,Best Unisex Salon in Andheri West,Top Men's Salon in Andheri West,Salon for Female's in Andheri West,Best Beauty Services Salon in Andheri,Best Hairstyles Salon in Andheri,Best Unisex Salon near Andheri,Beauty Salon in Andheri" />
    <link rel="canonical" href="https://diamondunisexsalon.in/" />
    <meta http-equiv="cache-control" content="no-cache" />
    <meta http-equiv="expires" content="0" />
    <meta http-equiv="pragma" content="no-cache" />
    <meta property="og:title" content=" Best Beauty Services Salon in Andheri | Diamond Unisex Salon" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://diamondunisexsalon.in/best-beauty-services-salon-andheri/" />
    <meta property="og:description" content=" Indulge in the Best Beauty Services at our Salon in Andheri. Our skilled professionals redefine elegance, offering a diverse range of services." />
    <meta property="og:image" content="https://diamondunisexsalon.in/static/media/LOGO.5a13bd8ea6fa0f8dc3bb.png" />
  </MetaTags>
      <div className="ttm-page-title-row">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="title-box text-center">
          <div className="page-title-heading">
            <h1>Best Beauty Services Salon in Andheri</h1>
          </div>
          {/* /.page-title-captions */}
         
        </div>
      </div>
      {/* /.col-md-12 */}
    </div>
    {/* /.row */}
  </div>
  {/* /.container */}
</div>

<div style={{paddingTop:"20px"}}>
<div className="container">
          <div id="image-gallery" className="row">
            <a
              className="col-md-4 "
              style={{ paddingBottom: "20px" }}
              href={g1}
              data-lg-size="1600-2400"
              data-lg-srcset={g1}
            >
              <img
                src={g1}
                style={{ height: "300px", width: "100%" }}
                alt="Enquery"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href={g2}
              data-lg-size="1600-2400"
              data-lg-srcset={g2}
            >
              <img
                src={g2}
                style={{ height: "300px", width: "100%" }}
                alt="Banner 1"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href={g3}
              data-lg-size="1600-2400"
              data-lg-srcset={g3}
            >
              <img
                src={g3}
                style={{ height: "300px", width: "100%" }}
                alt="Banner 6"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href={g4}
              data-lg-size="1600-2400"
              data-lg-srcset={g4}
            >
              <img
                src={g4}
                style={{ height: "300px", width: "100%" }}
                alt="Banner 6"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href={g5}
              data-lg-size="1600-2400"
              data-lg-srcset={g5}
            >
              <img
                src={g5}
                style={{ height: "300px", width: "100%" }}
                alt="Banner 6"
              />
            </a>
            <a
              className="col-md-4"
              style={{ paddingBottom: "20px" }}
              href={g6}
              data-lg-size="1600-2400"
              data-lg-srcset={g6}
            >
              <img
                src={g6}
                style={{ height: "300px", width: "100%" }}
                alt="Banner 6"
              />
            </a>
          </div>
        </div>
</div>
<div className="container">
  <p style={{textAlign:"justify"}}>
  Welcome to our best beauty services salon in Andheri, a visual showcase capturing the essence of beauty and excellence at our salon. Immerse yourself in the world of transformative styles and meticulous craftsmanship as we present a curated collection of moments that define our commitment to enhancing your natural allure.
  </p>
  <p style={{textAlign:"justify"}}>
  Our gallery unfolds with snapshots of expertly crafted hair transformations, showcasing the artistry behind our precision haircuts, vibrant global colors, seamless ombre blends, and the glossy sophistication of keratin treatments. Each image tells a story of personalized care, where our talented stylists bring out the unique beauty in every client.
  </p>
  <p style={{textAlign:"justify"}}>
  Explore the elegance of our nail care services, from the vibrant hues of gel polish to the flawless finish of meticulously executed manicures and pedicures. At our best beauty services salon in Andheri, our nail gallery captures the details of artistic expression and the pampering experience that leaves hands and feet looking and feeling exquisite.
  </p>
  <p style={{textAlign:"justify"}}>
  Journey through the enchanting world of bridal beauty, where our skilled makeup artists weave magic to enhance the natural radiance of brides. The bridal gallery unfolds with images of personalized makeup looks, reflecting the joy and confidence of brides on their special day.
  </p>
  <p style={{textAlign:"justify"}}>
  Witness the serenity of our best beauty services salon in Andheri, showcased in images of tranquil massage rooms, skilled therapists, and clients immersed in relaxation during body massages and rejuvenating treatments. The gallery paints a picture of tranquility, inviting you to experience the ultimate in spa indulgence.
  </p>
  <p style={{textAlign:"justify"}}>
  Our commitment to excellence extends beyond beauty services. The gallery captures the ambiance of our salon, where modern sophistication meets warmth and comfort. It reflects the dedication of our team to provide a welcoming environment where clients can unwind and leave feeling refreshed.
  </p>
  <p style={{textAlign:"justify"}}>
  As you browse through our gallery, we invite you to envision yourself as part of these moments. Each image is a testament to the passion and expertise that define our best beauty services salon in Andheri – a place where beauty meets artistry, and every client is a canvas for our creative expression.
  </p>
</div>
    </div>
  )
}

export default Gallery
