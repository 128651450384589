import React from 'react'
import { FaAngleDoubleRight } from "react-icons/fa";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import { Pagination } from 'swiper/modules';
const Serviceslider = () => {
  return (
    <div>
    <section className="ttm-row mt_100 p-0 res-991-mt_80">
  <div className="container">
    {/* row */}
    <div className="row">
    <Swiper
        slidesPerView={1}
        spaceBetween={10}
        // pagination={{
        //   clickable: true,
        // }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide> <div className="" style={{margin:"10px"}}>
        <div className="featured-imagebox featured-imagebox-post style1 res-767-mb-15">
          <div className="ttm-post-thumbnail featured-thumbnail">
            <img
              className="img-fluid"
              src="https://i.pinimg.com/736x/0a/21/70/0a217095d0a9aa63c28a6adca86c8a82.jpg"
              alt="image"
              style={{width:"100%",height:"250px"}}
            />
          </div>
          <div className="featured-content box-shadow">
            <div className="featured-title">
              {/* featured-title */}
              <h5>
                <a href="#" style={{color:"#333"}}>Hair Cut</a>
              </h5>
            </div>
            <div className="featured-desc">
              {/* featured-title */}
              <p>
              Experience precision and style at our unisex beauty salon in Andheri, where every haircut is a personalized work of art. Elevate your look with our expert team dedicated to showcasing your individuality.

              </p>
            </div>
            <div className="ttm-border-seperator" />
            
          </div>
        </div>
      </div></SwiperSlide>
      <SwiperSlide>
      <div className=""  style={{margin:"10px"}}>
        <div className="featured-imagebox featured-imagebox-post style1 res-767-mb-15">
          <div className="ttm-post-thumbnail featured-thumbnail">
            <img
              className="img-fluid"
              src="https://media.istockphoto.com/id/519396308/photo/barber-with-old-fashioned-black-razor.jpg?s=612x612&w=0&k=20&c=dorYoYaj6PyEPIougRHf45bKU7s75txUw2EgpTMo4Mg="
              alt="image"
              style={{width:"100%",height:"250px"}}
            />
          </div>
          <div className="featured-content box-shadow">
            <div className="featured-title">
              {/* featured-title */}
              <h5>
                <a href="#" style={{color:"#333"}}>Clean Shave</a>
              </h5>
            </div>
            <div className="featured-desc">
              {/* featured-title */}
              <p>
              Revel in the art of grooming at our unisex beauty salon in Andheri, where a clean shave is more than a service—it's a precision ritual. Experience the epitome of smoothness with our expert barbers, dedicated to refining your look with meticulous care.

              </p>
            </div>
            <div className="ttm-border-seperator" />
           
          </div>
        </div>
      </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className=""  style={{margin:"10px"}}>
        <div className="featured-imagebox featured-imagebox-post style1">
          <div className="ttm-post-thumbnail featured-thumbnail">
            <img
              className="img-fluid"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0blkj_IcqucqfHyemwDQqXXRDMef34jPwNA&usqp=CAU"
              alt="image"
              style={{width:"100%",height:"250px"}}
            />
          </div>
          <div className="featured-content box-shadow">
            <div className="featured-title">
              {/* featured-title */}
              <h5>
                <a href="#" style={{color:"#333"}}>Facial</a>
              </h5>
            </div>
            <div className="featured-desc">
              {/* featured-title */}
              <p>
              Revitalize your skin's natural glow at our salon, where indulgent facials are a harmonious blend of luxury and skincare expertise. Experience personalized treatments that leave you refreshed, radiant, and ready to face the world.
              </p>
            </div>
            <div className="ttm-border-seperator" />
           
          </div>
        </div>
      </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className=""  style={{margin:"10px"}}>
        <div className="featured-imagebox featured-imagebox-post style1">
          <div className="ttm-post-thumbnail featured-thumbnail">
            <img
              className="img-fluid"
              src="https://barbersurgeonsguild.com/wp-content/uploads/2018/03/BSG_10-21-0227-scaled.jpg"
              alt="image"
              style={{width:"100%",height:"250px"}}
            />
          </div>
          <div className="featured-content box-shadow">
            <div className="featured-title">
              {/* featured-title */}
              <h5>
                <a href="#" style={{color:"#333"}}>  Clean Up</a>
              </h5>
            </div>
            <div className="featured-desc">
              {/* featured-title */}
              <p>
              Transform at our unisex beauty salon in Andheri, where we seamlessly blend artistry and expertise in hair color and makeup. Elevate your style with personalized treatments that reflect your unique beauty.
              </p>
            </div>
            <div className="ttm-border-seperator" />
           
          </div>
        </div>
      </div>
      </SwiperSlide>
      
      <SwiperSlide>
      <div className=""  style={{margin:"10px"}}>
        <div className="featured-imagebox featured-imagebox-post style1">
          <div className="ttm-post-thumbnail featured-thumbnail">
            <img
              className="img-fluid"
              src="https://media.istockphoto.com/id/165899083/photo/foot-care.jpg?s=612x612&w=0&k=20&c=OTi92m4hw-d8xvNPxPu5J6y3i4oHvWjnee0Pvd4uplg="
              alt="image"
              style={{width:"100%",height:"250px"}}
            />
          </div>
          <div className="featured-content box-shadow">
            <div className="featured-title">
              {/* featured-title */}
              <h5>
                <a href="#" style={{color:"#333"}}>Manicure pedicure</a>
              </h5>
            </div>
            <div className="featured-desc">
              {/* featured-title */}
              <p>
                Professional hair stylist analysescalp conditions to determine
                th type of treatment need.
              </p>
            </div>
            <div className="ttm-border-seperator" />
            
          </div>
        </div>
      </div>
      </SwiperSlide>
      
      </Swiper>
     
    
      
    </div>
    {/* row end*/}
  </div>
</section>

    </div>
  )
}

export default Serviceslider
