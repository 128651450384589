import React from "react";
import sli from "../Asset/slider1.jpg";
import h1 from "../Asset/home/h1.webp"
const Sliderr = () => {
  return (
    <div>
    <div className="ttm-page-title-rownew" style={{backgroundImage:`${h1} !important`}}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <p className="bannerstyle">Achieve Your Style</p>
                  <h1>WHERE WOMENS WANTS</h1>
                  <br />
                  <h1>TO LOOK THERE VERY BEST </h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper" style={{paddingTop:"30px"}}>
                  <span>
                  <a
  id="slider-2-slide-4-layer-7"
  className="rs-layer ttm-btn ttm-btn-style-border ttm-btn-color-skincolor"
  href="/top-mens-salon-andheri-west"
  target="_self"
  rel="nofollow"
  data-type="text"
  data-color="#bf9456"
  data-rsp_ch="on"
  data-xy="x:c;y:m;yo:234px,234px,108px,68px;"
  data-text="w:normal;s:15,15,14,12;l:20,20,12,10;fw:500;"
  data-padding="t:15,15,12,10;r:35,35,25,20;b:15,15,12,10;l:35,35,25,20;"
  data-border="bos:solid;boc:#bf9456;bow:1px,1px,1px,1px;"
  data-frame_0="sX:0.8;sY:0.8;"
  data-frame_1="e:Power4.easeOut;st:1330;sp:600;sR:1330;"
  data-frame_999="o:0;st:w;sR:7070;"
  data-frame_hover="c:#fff;bgc:#bf9456;boc:#bf9456;bos:solid;bow:1px,1px,1px,1px;"
  style={{
    zIndex: 15,
    fontFamily: "Poppins",
    visibility: "visible",
    textAlign: "left",
   
    letterSpacing: 0,
    fontWeight: 500,
    fontSize: 15,
    borderColor: "rgb(191, 148, 86)",
    borderStyle: "solid",
    borderWidth: 1,
    margin: 0,
    borderRadius: 0,
    padding: "15px 35px",
    color: "rgb(191, 148, 86)",
    textDecoration: "none",
    whiteSpace: "nowrap",
    width: "auto",
    height: "auto",
    minHeight: 0,
    minWidth: 0,
    maxHeight: "none",
    maxWidth: "none",
    backgroundColor: "rgba(0, 0, 0, 0)",
    opacity: 1,
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    transformOrigin: "50% 50% 0px"
  }}
  data-idcheck="true"
  data-stylerecorder="true"
  data-initialised="true"
>
  Our Great Services
</a>

                  </span>
                  {/* <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                */}
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>

    </div>
  );
};

export default Sliderr;
