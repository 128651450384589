import React from 'react'
import { FaAngleDoubleRight } from "react-icons/fa";
import { MetaTags } from 'react-meta-tags';
import w1 from "../Asset/woman/w1.webp"
import w2 from "../Asset/woman/w2.webp"
import w3 from "../Asset/woman/w3.webp"
import w4 from "../Asset/woman/w4.webp"
import w5 from "../Asset/woman/w5.webp"
import w6 from  "../Asset/woman/w6.webp"
import w7 from "../Asset/woman/w7.webp"
import w8 from "../Asset/woman/w8.webp"
import w9 from "../Asset/woman/w9.webp"
import w10 from "../Asset/woman/w10.webp"
import w11 from "../Asset/woman/w11.webp"
const ServiceWoman = () => {
  return (
    <div>
         <MetaTags>
    <title>Salon for Female's in Andheri West | Diamond Unisex Salon</title>
    <meta title=" Salon for Female's in Andheri West | Diamond Unisex Salon" />
    <meta name="description" content="Experience the epitome of beauty at our exclusive Salon for Females in Andheri West. Our dedicated team of experts caters to women's beauty needs." />
    <meta name="keywords" content="Unisex Beauty Salon in Andheri,Best Unisex Salon in Andheri West,Top Men's Salon in Andheri West,Salon for Female's in Andheri West,Best Beauty Services Salon in Andheri,Best Hairstyles Salon in Andheri,Best Unisex Salon near Andheri,Beauty Salon in Andheri" />
    <link rel="canonical" href="https://diamondunisexsalon.in/" />
    <meta http-equiv="cache-control" content="no-cache" />
    <meta http-equiv="expires" content="0" />
    <meta http-equiv="pragma" content="no-cache" />
    <meta property="og:title" content=" Salon for Female's in Andheri West | Diamond Unisex Salon" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://diamondunisexsalon.in/top-mens-salon-andheri-west/" />
    <meta property="og:description" content="Experience the epitome of beauty at our exclusive Salon for Females in Andheri West. Our dedicated team of experts caters to women's beauty needs." />
    <meta property="og:image" content="https://diamondunisexsalon.in/static/media/LOGO.5a13bd8ea6fa0f8dc3bb.png" />
  </MetaTags>
    <div className="ttm-page-title-row">
<div className="container">
  <div className="row">
    <div className="col-md-12">
      <div className="title-box text-center">
        <div className="page-title-heading">
          <h1>Salon for Female's in Andheri West</h1>
        </div>
        {/* /.page-title-captions */}
   
      </div>
    </div>
    {/* /.col-md-12 */}
  </div>
  {/* /.row */}
</div>
{/* /.container */}
</div>
<section className="ttm-row beauty-service-section bg-img4">
<div className="beauty-service-title-wrapper">
  <div className="container">
    <div className="row">
      {/* row */}
      <div className="col-lg-7 col-md-7 col-sm-9 m-auto">
        {/* section title */}
        <div className="section-title with-desc text-center clearfix">
          <div className="title-header">
            <h5 className="ttm-textcolor-skincolor">Welcome</h5>
            <h2 className="title">OUR BEAUTY SERVICES</h2>
          </div>
          <div className="title-desc">
          Welcome to our haven of salon for female's in Andheri West, where indulgence meets expertise. At our salon, we take pride in offering a diverse array of services dedicated to enhancing and celebrating the beauty of every woman.
          </div>
        </div>
        {/* section title end */}
      </div>
    </div>
  </div>
</div>
<div className="ttm-bgcolor-white pb-70 res-991-pb-20">
  <div className="container">
    <div className="row">
      <div className="col-12 p-0">
        <div
          className="services2-slide owl-carousel mt_100 owl-theme owl-loaded"
          data-item={3}
          data-nav="true"
          data-dots="false"
          data-auto="false"
        >
          <div className="owl-stage-outer">
            <div
              className="owl-stage"
              style={{
              
              }}
            >
              <div className="row">
                  <div className="col-md-4"><div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={w1}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#"  style={{color:"#333"}}>
                        Hair cut
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      At our salon for female's in Andheri West, female haircuts are crafted with precision and a touch of glamour. Our skilled stylists bring your vision to life, ensuring you leave with a look that radiates confidence.

                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More  <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div></div>
                  <div className="col-md-4">
                  <div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={w2}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#"  style={{color:"#333"}}>
                        Hair Wash
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Indulge in a rejuvenating hair wash experience at our salon, where expert care meets relaxation, leaving your locks refreshed and revitalized. Elevate your hair care routine with us today.
                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div>
                  </div>
                  <div className="col-md-4">
                  <div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={w3}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#"  style={{color:"#333"}}>
                        Hair Root Touch
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Our skilled professionals ensure flawless hair touch-ups, leaving you with a vibrant and confident look. Experience the artistry of root touch-ups that redefine your style.
                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More  <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div>
                  </div>
                  <div className="col-md-4"><div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={w4}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#"  style={{color:"#333"}}>
                        Hair Global Colour
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Transform your look with our salon's expert global hair coloring services, where creativity meets precision for a stunning, personalized color experience. Discover a world of vibrant possibilities as we bring your hair dreams to life.
                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More  <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div></div>
                  <div className="col-md-4">
                  <div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={w5}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#" style={{color:"#333"}}>
                        Hair Highlight
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Elevate your look with our salon's masterful hair highlights, where skilled professionals create personalized and radiant styles that illuminate your beauty.

                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div>
                  </div>
                  <div className="col-md-4">
                  <div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={w6}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#"  style={{color:"#333"}}>
                        Smoothing Treatment
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Experience the epitome of sleekness and shine at our salon for female's in Andheri West, where our expert smoothening treatments transform your hair into luxuriously smooth and silky perfection.
                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More  <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div>
                  </div>
                  <div className="col-md-4"><div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={w7}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#"  style={{color:"#333"}}>
                        Botox Treatment
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Revitalize your locks with precision and sophistication at our salon, where Botox hair treatments redefine smoothness and restore a youthful, lustrous glow.

                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More  <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div></div>
                  <div className="col-md-4">
                  <div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={w8}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#"  style={{color:"#333"}}>
                        Keratin treatment
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Our keratin treatments deliver silky smoothness and long-lasting radiance, unlocking the true potential of your locks.

                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div>
                  </div>
                  <div className="col-md-4">
                  <div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={w9}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#"  style={{color:"#333"}}>
                        Nanoplast Treatment
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Our nanoplast treatments combine cutting-edge technology and skilled artistry for hair that radiates health, strength, and brilliance.
                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More  <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div>
                  </div>
                  <div className="col-md-4"><div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={w10}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#"  style={{color:"#333"}}>
                        Ombre Hair Colour
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Our ombre hair color services seamlessly blend expertise and creativity to achieve a stunning and customized transition of hues.
                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More  <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div></div>
                  <div className="col-md-4">
                  <div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={w11}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#" style={{color:"#333"}}>
                        Balayage Colour
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Reveal your natural beauty with our salon's expert balayage color technique, where skilled professionals craft effortless and sun-kissed looks that embrace the essence of individuality.
                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div>
                  </div>
                 
              </div>
             
             
            </div>
          </div>
        
        </div>
      </div>
      
    </div>
  </div>
</div>
</section>
<div className="container">
        {/* <p style={{textAlign:"justify"}}>
        Welcome to our haven of salon for female's in Andheri West, where indulgence meets expertise. At our salon, we take pride in offering a diverse array of services dedicated to enhancing and celebrating the beauty of every woman.

        </p> */}
        <p style={{textAlign:"justify"}}>
        Our skilled team of professionals brings passion and precision to each service, whether it's the artistry of a haircut, the seamless elegance of hair coloring, the pampering touch of a facial, or the glamor of a perfectly executed manicure. Salon for female's in Andheri West understand that every woman is unique, and our personalized approach ensures that each service is tailored to accentuate individual beauty.


        </p>
        <p style={{textAlign:"justify"}}>
        Step into our welcoming space, designed for relaxation and rejuvenation. From the moment you enter, our goal is to create an atmosphere of tranquility where you can unwind and indulge in the transformative experience of our beauty services.

        </p>
        <p style={{textAlign:"justify"}}>
        Experience the latest trends and timeless classics as we strive to exceed your expectations with every visit. Our commitment goes beyond beauty – it's about empowering women to feel confident, radiant, and utterly beautiful. Discover the epitome of female beauty services at our salon for female's in Andheri West, where you are not just a client; you are a canvas for our expertise and creativity.

        </p>
        
      </div>

  </div>   
  )
}

export default ServiceWoman
