import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { MetaTags } from 'react-meta-tags';
const Faq = () => {
  const [activeToggle, setActiveToggle] = useState(null);

  const handleToggleClick = (index) => {
    setActiveToggle(activeToggle === index ? null : index);
  };
  return (
    <div>
   
      <MetaTags>
    <title>Best Hairstyles Salon in Andheri | Diamond Unisex Salon</title>
    <meta title=" Best Hairstyles Salon in Andheri | Diamond Unisex Salon" />
    <meta name="description" content="Step into the realm of cutting-edge style at the Best Hairstyle Salon in Andheri. Our skilled stylists create masterpieces that define trends." />
    <meta name="keywords" content="Unisex Beauty Salon in Andheri,Best Unisex Salon in Andheri West,Top Men's Salon in Andheri West,Salon for Female's in Andheri West,Best Beauty Services Salon in Andheri,Best Hairstyles Salon in Andheri,Best Unisex Salon near Andheri,Beauty Salon in Andheri" />
    <link rel="canonical" href="https://diamondunisexsalon.in/" />
    <meta http-equiv="cache-control" content="no-cache" />
    <meta http-equiv="expires" content="0" />
    <meta http-equiv="pragma" content="no-cache" />
    <meta property="og:title" content=" Best Hairstyles Salon in Andheri | Diamond Unisex Salon" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://diamondunisexsalon.in/top-mens-salon-andheri-west/" />
    <meta property="og:description" content="Step into the realm of cutting-edge style at the Best Hairstyle Salon in Andheri. Our skilled stylists create masterpieces that define trends." />
    <meta property="og:image" content="https://diamondunisexsalon.in/static/media/LOGO.5a13bd8ea6fa0f8dc3bb.png" />
  </MetaTags>
      <div className="ttm-page-title-row">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="title-box text-center">
          <div className="page-title-heading">
            <h1>Best Hairstyles Salon Andheri</h1>
          </div>
          {/* /.page-title-captions */}
          <div className="breadcrumb-wrapper">
            <span>
              <a title="Homepage" href="index.html">
                home
              </a>
            </span>
            <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
            <span>F.A.Q.</span>
          </div>
        </div>
      </div>
      {/* /.col-md-12 */}
    </div>
    {/* /.row */}
  </div>
  {/* /.container */}
</div>
<div className="site-main">
  {/* faq-section */}
  <section className="ttm-row faq-section clearfix">
    <div className="container">
      <div className="row">
        {/* row */}
        <div className="col-lg-7 col-md-7 col-sm-9 m-auto">
          {/* section title */}
          <div className="section-title with-desc text-center clearfix">
            <div className="title-header">
              <h5 className="ttm-textcolor-skincolor">Have Any Questions?</h5>
              <h2 className="title">RECENTLY ASKED QUESTIONS</h2>
            </div>
            <div className="title-desc">
              Below you’ll find answers to some of the most frequently asked
              questions. We are constantly adding most frequently asked question
              to this page.
            </div>
          </div>
          {/* section title end */}
        </div>
      </div>
      <div className="row pt-20 pb-50 res-991-p-0">
        <div className="col-lg-6">
          {/* accordion */}
          <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0" >
        <Accordion.Header> What hair services do you provide?</Accordion.Header>
        <Accordion.Body>
        We offer a variety of hair services, including haircuts, styling, coloring, highlights, and hair extensions. Our skilled stylists can help you achieve the look you desire.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1" >
        <Accordion.Header>What hair color options are available at Diamond Salon?</Accordion.Header>
        <Accordion.Body>
        We offer a wide range of hair color options, from classic shades to trendy hues. Our stylists use high-quality products to ensure a vibrant and long-lasting color.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header> Do you provide beard shaving services?</Accordion.Header>
        <Accordion.Body>
        Yes, we offer beard shaving services, including grooming and styling. Our experienced barbers will ensure a precise and stylish beard shape according to your preferences.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>What can I expect from a manicure or pedicure at Diamond Salon?</Accordion.Header>
        <Accordion.Body>
        Our manicure and pedicure services include nail shaping, cuticle care, exfoliation, massage, and polish application. We use high-quality products to leave your hands and feet looking and feeling rejuvenated.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
         
          {/* accordion  end*/}
        </div>
        <div className="col-lg-6">
          {/* accordion */}
          <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header> Do you offer any packages or discounts for multiple services?</Accordion.Header>
        <Accordion.Body>
        Yes, we have various packages and promotions available throughout the year. Check our website or inquire at the salon for the latest offers and discounts.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>What safety measures are in place at Diamond Salon?</Accordion.Header>
        <Accordion.Body>
        Your safety is our priority. We follow strict hygiene protocols, regularly sanitize our tools and equipment, and adhere to all health guidelines to create a clean and comfortable environment for our clients.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header> How long do hair color appointments typically take at Diamond Salon?</Accordion.Header>
        <Accordion.Body>
        The duration of a hair color appointment depends on the specific service and your hair type. On average, it can take anywhere from 1.5 to 3 hours. During your consultation, our stylist will provide a more accurate time estimate based on your individual needs.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header> Are your waxing services suitable for sensitive skin?</Accordion.Header>
        <Accordion.Body>
        Yes, our waxing services are designed to be gentle on the skin. We use high-quality, hypoallergenic wax and take extra care to minimize discomfort. If you have concerns about sensitivity, please inform our estheticians during your appointment so they can provide the most comfortable experience possible.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
       
      
          {/* accordion  end*/}
        </div>
      </div>
      <div className="row res-991-pt-10">
        {/* row */}
        <div className="col-lg-7 col-md-7 col-sm-9 m-auto">
          {/* section title */}
          <div className="section-title with-desc text-center clearfix">
            <div className="title-header">
              <h2 className="title">WANNA QUICK SUPPORT?</h2>
            </div>
            <div className="title-desc">
            Thank you for choosing best hairstyle salon in Andheri. We look forward to exceeding your expectations and making every visit a delightful experience.

            </div>
          </div>
          {/* section title end */}
          <p className="text-center mb-0">
            <a
              href="#"
              className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-darkgrey"
              style={{color:"#333"}}
            >
              Enquiry Now
            </a>
          </p>
        </div>
      </div>
    </div>
  </section>
</div>
<div className="container">
        <p style={{textAlign:"justify"}}>
        Welcome to the best hairstyle salon in Andheri, where beauty meets expertise in an oasis of elegance. Our salon is dedicated to providing a haven for self-care and pampering, ensuring each client leaves feeling rejuvenated and confident.

        </p>
        <p style={{textAlign:"justify"}}>
        At the best hairstyle salon in Andheri, we pride ourselves on a team of skilled professionals committed to delivering personalized services tailored to your unique needs. From trendy haircuts to exquisite nail art, our stylists and technicians are passionate about creating stunning transformations. With a keen eye for the latest trends and a commitment to ongoing training, our team stays at the forefront of the beauty industry.


        </p>
        <p style={{textAlign:"justify"}}>
        Why choose the best hairstyle salon in Andheri? Our commitment to excellence extends beyond our services. We prioritize cleanliness and hygiene, ensuring a safe and welcoming environment for our clients. We use premium products that nourish and enhance, promoting both beauty and well-being.


        </p>
        
        
      </div>

    </div>
  )
}

export default Faq
