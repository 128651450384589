import React from 'react'
import salon3 from "../Asset/salon3.png"
import salon4 from "../Asset/salon5.png"
import ab1 from "../Asset/abicons/ab1.png"
import ab2 from "../Asset/abicons/ab2.png"
import ab3 from "../Asset/abicons/ab3.png"
import ab4 from "../Asset/abicons/ab4.png"
import { IoMdCall } from "react-icons/io";
import Abtestimonial from '../components/Abtestimnial'
import { MetaTags } from 'react-meta-tags';
import abb1 from "../Asset/home/ab1.webp"
import abb2 from "../Asset/home/ab2.webp"
const Aboutus = () => {
  return (
  
    <div>
        <MetaTags>
    <title>Best Unisex Salon in Andheri West | Diamond Unisex Salon</title>
    <meta title=" Best Unisex Salon in Andheri West | Diamond Unisex Salon" />
    <meta name="description" content=" Indulge in luxury at the Best Unisex Salon in Andheri West. Elevate your style with our expert stylists, offering a spectrum of cutting-edge services for both." />
    <meta name="keywords" content="Unisex Beauty Salon in Andheri,Best Unisex Salon in Andheri West,Top Men's Salon in Andheri West,Salon for Female's in Andheri West,Best Beauty Services Salon in Andheri,Best Hairstyles Salon in Andheri,Best Unisex Salon near Andheri,Beauty Salon in Andheri" />
    <link rel="canonical" href="https://diamondunisexsalon.in/" />
    <meta http-equiv="cache-control" content="no-cache" />
    <meta http-equiv="expires" content="0" />
    <meta http-equiv="pragma" content="no-cache" />
    <meta property="og:title" content=" Best Unisex Salon in Andheri West | Diamond Unisex Salon" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://diamondunisexsalon.in/best-unisex-salon-andheri-west/" />
    <meta property="og:description" content=" Indulge in luxury at the Best Unisex Salon in Andheri West. Elevate your style with our expert stylists, offering a spectrum of cutting-edge services for both." />
    <meta property="og:image" content="https://diamondunisexsalon.in/static/media/LOGO.5a13bd8ea6fa0f8dc3bb.png" />
  </MetaTags>
      <div className="ttm-page-title-row">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="title-box text-center">
          <div className="page-title-heading">
            <h1>Best Unisex Salon in Andheri West</h1>
          </div>
          {/* /.page-title-captions */}
          
        </div>
      </div>
      {/* /.col-md-12 */}
    </div>
    {/* /.row */}
  </div>
  {/* /.container */}
</div>
<div className="site-main">
  {/* mission-section */}
  <section className="ttm-row mission-section clearfix ttm-bgcolor-grey">
    <div className="container">
      <div className="row">
        <div className="col-sm-6 position-relative">
          <img
            src={abb1}
            className="img-fluid"
            alt="single-img"
          />
          <div className="ttm-icon ttm-icon_element-color-skincolor ttm-bgcolor-white ttm-icon_element-size-lg ttm-icon_element-style-rounded">
            {/* <i className="flaticon-scissor-and-comb" /> */}
            <img src={salon3} alt="" style={{width:"33px"}} />
          </div>
        </div>
        <div className="col-sm-6 res-575-mt-15">
          <img
            src={abb2}
            className="img-fluid"
            alt="single-img"
          />
        </div>
      </div>
      <div className="row">
        {/* row */}
        <div className=" m-auto text-center mt-40">
          {/* section title */}
          <div className="section-title with-desc clearfix">
            <div className="title-header">
              <h2 className="title">OUR VISION AND MISION</h2>
            </div>
            <div className="row">
            <div className="col-md-6">

            <div className="title-desc" style={{textAlign:"justify"}}>
            At our best Unisex salon in Andheri west, our vision is to redefine beauty standards and empower individuals by enhancing their unique features. We strive to create a space where self-expression is celebrated, and confidence is ignited through personalized beauty experiences. In our best Unisex salon in Andheri west, our mission is to provide top-notch services, from cutting-edge hair styling and vibrant color treatments to flawless makeup applications.
            </div>
            </div>
            <div className="col-md-6">
            <div className="title-desc" style={{textAlign:"justify"}}>
            The best Unisex salon in Andheri west is committed to staying at the forefront of beauty trends, continuously evolving to meet the diverse needs of our clients. Embracing inclusivity, we aim to cater to a broad spectrum of styles, cultures, and preferences. Our skilled team of professionals is dedicated to fostering a welcoming environment where every client feels valued and beautiful.
            </div>
            </div>
            </div>
            
          </div>
          {/* section title end */}
          {/* <a href="#" className="ttm-btn ttm-btn-bgcolor-skincolor" style={{color:"#333" ,border:"0.5px solid #333"}}>
            read more!
          </a> */}
        </div>
      </div>
    </div>
  </section>
  {/* mission-section end */}
  {/* fid section */}
  <section className="ttm-row fid-section ttm-bgcolor-darkgrey ttm-bg ttm-bgimage-yes bg-img11 clearfix">
    <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
    <div className="container">
      <div className="row ttm-fid-row-wrapper">
        <div className="col-md-3 col-sm-6 col-12">
          {/*ttm-fid*/}
          <div className="ttm-fid inside ttm-fid-view-lefticon style2">
            <div className="ttm-fid-left">
              <div className="ttm-fid-icon-wrapper ttm-textcolor-skincolor">
                <img src={ab1} alt="" />
              </div>
              <h4 className="ttm-fid-inner">
                <span
                  data-appear-animation="animateDigits"
                  data-from={0}
                  data-to={15}
                  data-interval={1}
                  data-before=""
                  data-before-style="sup"
                  data-after=""
                  data-after-style="sub"
                  className="numinate completed"
                >
                  15
                </span>
              </h4>
            </div>
            <div className="ttm-fid-contents text-left">
              <h3 className="ttm-fid-title">Years Of Experience</h3>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12">
          {/*ttm-fid*/}
          <div className="ttm-fid inside ttm-fid-view-lefticon style2">
            <div className="ttm-fid-left">
              <div className="ttm-fid-icon-wrapper ttm-textcolor-skincolor">
                <img src={ab2} alt="" />
              </div>
              <h4 className="ttm-fid-inner">
                <span
                  data-appear-animation="animateDigits"
                  data-from={0}
                  data-to={320}
                  data-interval={10}
                  data-before=""
                  data-before-style="sup"
                  data-after=""
                  data-after-style="sub"
                  className="numinate completed"
                >
                  320
                </span>
              </h4>
            </div>
            <div className="ttm-fid-contents text-left">
              <h3 className="ttm-fid-title">professional Barber</h3>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12">
          {/*ttm-fid*/}
          <div className="ttm-fid inside ttm-fid-view-lefticon style2">
            <div className="ttm-fid-left">
              <div className="ttm-fid-icon-wrapper ttm-textcolor-skincolor">
                <img src={ab3} alt="" />
              </div>
              <h4 className="ttm-fid-inner">
                <span
                  data-appear-animation="animateDigits"
                  data-from={0}
                  data-to={45}
                  data-interval={5}
                  data-before=""
                  data-before-style="sup"
                  data-after=""
                  data-after-style="sub"
                  className="numinate completed"
                >
                  45
                </span>
              </h4>
            </div>
            <div className="ttm-fid-contents text-left">
              <h3 className="ttm-fid-title">Massage</h3>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12">
          {/*ttm-fid*/}
          <div className="ttm-fid inside ttm-fid-view-lefticon style2">
            <div className="ttm-fid-left">
              <div className="ttm-fid-icon-wrapper ttm-textcolor-skincolor">
                <img src={ab4} alt="" />
              </div>
              <h4 className="ttm-fid-inner">
                <span
                  data-appear-animation="animateDigits"
                  data-from={0}
                  data-to={2555}
                  data-interval={100}
                  data-before=""
                  data-before-style="sup"
                  data-after=""
                  data-after-style="sub"
                  className="numinate completed"
                >
                  2000
                </span>
                <span>&nbsp;+</span>
              </h4>
            </div>
            <div className="ttm-fid-contents text-left">
              <h3 className="ttm-fid-title">Customers</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="featured-icon-box icon-align-before-content style7 mt-50 ml-15 res-991-mt-30 res-767-mt-10">
          <div className="featured-icon">
            {/*  featured-icon */}
            <div className="ttm-icon ttm-icon_element-color-skincolor ttm-bgcolor-white ttm-icon_element-style-rounded ttm-icon_element-size-sm">
              <i className="fa fa-phone" />
              <IoMdCall className='fa fa-phone' />
              {/*  ttm-icon */}
            </div>
          </div>
          <div className="featured-content">
            {/*  featured-content */}
            <div className="featured-title">
              {/*  featured-title */}
              <h3 className="ttm-textcolor-white">
                First Step Call Lirena Now !
              </h3>
            </div>
            <div className="featured-desc">
              {/*  featured-desc */}
              <p>Phone : +91-8828882887</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* fid section end*/}
  {/* testimonial section */}
  <section className="ttm-row testimonial-section clearfix">
    <div className="container">
      <div className="row">
        <div className="col-md-7 col-sm-12">
          <div className="ttm-skill-wrapper col-bg-img-five ttm-col-bgimage-yes ttm-bg res-767-h-auto ml_390 res-767-ml_100 res-767-mr_20">
            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" />
            <div className="layer-content spacing-6">
              <div className="section-title with-desc clearfix">
                <div className="title-header">
                  <h5 className="ttm-textcolor-skincolor">Skills</h5>
                  <h2 className="title">PROFESSIONAL SKILLS</h2>
                </div>
              </div>
              <div className="ttm-progress-bar" data-percent="80%">
                <div className="progress-bar-title">Flexibility</div>
                {/* progress-bar-title */}
                <div className="progress-bar-inner">
                  <div
                    className="progress-bar progress-bar-color-bar_skincolor"
                    style={{ width: "80%" }}
                  />
                </div>
                <div className="progress-bar-percent" data-percentage={80}>
                  80%
                </div>
                {/* progress-bar-percent */}
              </div>
              <div className="ttm-progress-bar" data-percent="90%">
                <div className="progress-bar-title">Organization</div>
                {/* progress-bar-title */}
                <div className="progress-bar-inner">
                  <div
                    className="progress-bar progress-bar-color-bar_skincolor"
                    style={{ width: "90%" }}
                  />
                </div>
                <div className="progress-bar-percent" data-percentage={90}>
                  90%
                </div>
                {/* progress-bar-percent */}
              </div>
              <div className="ttm-progress-bar" data-percent="70%">
                <div className="progress-bar-title">Physical Stamina</div>
                {/* progress-bar-title */}
                <div className="progress-bar-inner">
                  <div
                    className="progress-bar progress-bar-color-bar_skincolor"
                    style={{ width: "70%" }}
                  />
                </div>
                <div className="progress-bar-percent" data-percentage={70}>
                  70%
                </div>
                {/* progress-bar-percent */}
              </div>
              <div className="ttm-progress-bar" data-percent="50%">
                <div className="progress-bar-title">Communication</div>
                {/* progress-bar-title */}
                <div className="progress-bar-inner">
                  <div
                    className="progress-bar progress-bar-color-bar_skincolor"
                    style={{ width: "50%" }}
                  />
                </div>
                <div className="progress-bar-percent" data-percentage={50}>
                  50%
                </div>
                {/* progress-bar-percent */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5 col-sm-12 ttm-textcolor-white">
          <div className="col-bg-img-six ttm-col-bgimage-yes ttm-bg">
            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" />
            <div className="spacing-7 position-relative">
              <span className="ttm-testi-quote">

                <img src={salon4} alt="" style={{width:"25px"}} />
              </span>
              <div className="section-title with-desc clearfix mb-0">
                <div className="title-header">
                  <h5 className="ttm-textcolor-skincolor">Testimonial</h5>
                  <h2 className="title">WHAT CLIENTS SAYING</h2>
                </div>
              </div>
           
              <Abtestimonial/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* testimonial section end*/}
  {/* Team section */}
 
  {/* Team section end*/}
                
</div>


    </div>
  )
}

export default Aboutus
