import React from 'react'
import { FaAngleDoubleRight } from "react-icons/fa";
import { MetaTags } from 'react-meta-tags';
import b1 from "../Asset/buity/b1.webp"
import b2 from "../Asset/buity/b2.webp"
import b3 from "../Asset/buity/b3.webp"
import b4 from "../Asset/buity/b4.webp"
import b5 from "../Asset/buity/b5.webp"
import b6 from "../Asset/buity/b6.webp"
import b7 from "../Asset/buity/b7.webp"
import b8 from "../Asset/buity/b8.webp"
import b9 from "../Asset/buity/b9.webp"
import b10 from "../Asset/buity/b10.webp"
import b11 from "../Asset/buity/b11.webp"
import b12 from "../Asset/buity/b12.webp"
import b13 from "../Asset/buity/b13.webp"
import b14 from "../Asset/buity/b14.webp"
const Beautyservices = () => {
  return (
    <div>
       <MetaTags>
    <title>Beauty Salon in Andheri | Diamond Unisex Salon</title>
    <meta title=" Beauty Salon in Andheri | Diamond Unisex Salon" />
    <meta name="description" content="Discover the epitome of beauty at Radiant Glamour, the leading Beauty Salon in Andheri. Our skilled stylists offer personalized makeovers." />
    <meta name="keywords" content="Unisex Beauty Salon in Andheri,Best Unisex Salon in Andheri West,Top Men's Salon in Andheri West,Salon for Female's in Andheri West,Best Beauty Services Salon in Andheri,Best Hairstyles Salon in Andheri,Best Unisex Salon near Andheri,Beauty Salon in Andheri" />
    <link rel="canonical" href="https://diamondunisexsalon.in/" />
    <meta http-equiv="cache-control" content="no-cache" />
    <meta http-equiv="expires" content="0" />
    <meta http-equiv="pragma" content="no-cache" />
    <meta property="og:title" content=" Beauty Salon in Andheri | Diamond Unisex Salon" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://diamondunisexsalon.in/beauty-salon-andheri/" />
    <meta property="og:description" content="Discover the epitome of beauty at Radiant Glamour, the leading Beauty Salon in Andheri. Our skilled stylists offer personalized makeovers." />
    <meta property="og:image" content="https://diamondunisexsalon.in/static/media/LOGO.5a13bd8ea6fa0f8dc3bb.png" />
  </MetaTags>
        <div>
    <div className="ttm-page-title-row">
<div className="container">
  <div className="row">
    <div className="col-md-12">
      <div className="title-box text-center">
        <div className="page-title-heading">
          <h1>Beauty Salon in Andheri</h1>
        </div>
        {/* /.page-title-captions */}
        <div className="breadcrumb-wrapper">
          <span>
            <a title="Homepage" href="index.html">
              home
            </a>
          </span>
          <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
          <span>Services 2</span>
        </div>
      </div>
    </div>
    {/* /.col-md-12 */}
  </div>
  {/* /.row */}
</div>
{/* /.container */}
</div>
<section className="ttm-row beauty-service-section bg-img4">
<div className="beauty-service-title-wrapper">
  <div className="container">
    <div className="row">
      {/* row */}
      <div className="col-lg-7 col-md-7 col-sm-9 m-auto">
        {/* section title */}
        <div className="section-title with-desc text-center clearfix">
          <div className="title-header">
            <h5 className="ttm-textcolor-skincolor">Welcome</h5>
            <h2 className="title">OUR BEAUTY SERVICES</h2>
          </div>
          <div className="title-desc">
          Welcome to our Beauty salon in Andheri, where beauty meets expertise in a harmonious blend of services designed for both men and women. Our mission is to create a welcoming and inclusive space, offering a diverse range of beauty services that cater to everyone's unique preferences and needs.
          </div>
        </div>
        {/* section title end */}
      </div>
    </div>
  </div>
</div>
<div className="ttm-bgcolor-white pb-70 res-991-pb-20">
  <div className="container">
    <div className="row">
      <div className="col-12 p-0">
        <div
          className="services2-slide owl-carousel mt_100 owl-theme owl-loaded"
          data-item={3}
          data-nav="true"
          data-dots="false"
          data-auto="false"
        >
          <div className="owl-stage-outer">
            <div
              className="owl-stage"
              style={{
              
              }}
            >
              <div className="row">
                  <div className="col-md-4"><div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={b1}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#"  style={{color:"#333"}}>
                        Eyebrows
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Frame your beauty with precision at our salon, where eyebrow expertise meets artistry to sculpt, shape, and enhance your natural allure.



                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More  <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div></div>
                  <div className="col-md-4">
                  <div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={b2}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#"  style={{color:"#333"}}>
                        Face Wax
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Our face waxing services blend precision and care for a flawless, radiant complexion.
                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div>
                  </div>
                  <div className="col-md-4">
                  <div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={b3}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#"  style={{color:"#333"}}>
                        Manicure And Pedicure
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Our meticulous care and expert touch converge to create stunning manicures and pedicures that pamper and beautify your hands and feet.
                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More  <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div>
                  </div>
                  <div className="col-md-4"><div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={b4}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#"  style={{color:"#333"}}>
                        Waxing
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Step into a realm of smoothness at our salon, where our waxing services offer meticulous precision and gentle care, leaving your skin flawlessly hair-free. 

                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More  <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div></div>
                  <div className="col-md-4">
                  <div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={b5}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#" style={{color:"#333"}}>
                        Facial
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Our facials combine expert techniques and premium skincare to rejuvenate and enhance your beauty.


                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div>
                  </div>
                  <div className="col-md-4">
                  <div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={b6}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#"  style={{color:"#333"}}>
                        Clean Up
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Refresh your glow with our salon's meticulous clean-up services, where skilled professionals cleanse and revitalize your skin for a radiant and invigorated complexion.


                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More  <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div>
                  </div>
                  <div className="col-md-4"><div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={b7}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#"  style={{color:"#333"}}>
                        Gel Nail Polish
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Our gel nail polish applications blend creativity and precision to deliver vibrant, chip-resistant colors that redefine your nail elegance.

                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More  <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div></div>
                  <div className="col-md-4">
                  <div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={b8}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#"  style={{color:"#333"}}>
                        Bridal Makeup
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Elevate your bridal radiance at our salon, where skilled artists curate personalized bridal makeup to enhance your natural beauty, ensuring you radiate confidence on your special day.
                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div>
                  </div>
                  <div className="col-md-4">
                  <div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={b9}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#"  style={{color:"#333"}}>
                        Bridal Beauty Services
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Unleash your glamour at our salon, where party makeup services merge trendsetting artistry and sophistication to create show-stopping looks that dazzle throughout the night.

                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More  <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div>
                  </div>
                  <div className="col-md-4"><div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={b10}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#"  style={{color:"#333"}}>
                        Party Makeup 
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Our comprehensive beauty services merge expertise and elegance to make your wedding day a radiant and unforgettable experience.

                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More  <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div></div>
                  <div className="col-md-4">
                  <div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={b11}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#" style={{color:"#333"}}>
                        Foot Massage
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Relax and rejuvenate at our salon, where expert therapists deliver blissful foot massages, relieving tension and promoting ultimate serenity for your complete well-being.

                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div>
                  </div>
                  <div className="col-md-4">
                  <div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={b12}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#" style={{color:"#333"}}>
                        Back Massage
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Soothe away stress and tension at our salon, where skilled therapists provide back massages that blend relaxation and rejuvenation for a revitalized and blissful experience.

                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div>
                  </div>
                  <div className="col-md-4">
                  <div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={b13}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#" style={{color:"#333"}}>
                        Full Body Massage 
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Embark on a journey of relaxation and tranquility at our salon, where our skilled therapists offer full body massages, harmonizing body and mind for a rejuvenated and balanced you.
                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div>
                  </div>
                  <div className="col-md-4">
                  <div
                className="owl-item cloned"
                style={{  marginRight: 0 }}
              >
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="ttm-post-thumbnail featured-thumbnail">
                    <img  
                      className="img-fluid"
                      src={b14}
                      alt="image"
                       style={{width:"100%",height:"250px"}}
                    />
                  </div>
                  <div className="featured-content box-shadow">
                    <div className="featured-title">
                      {/* featured-title */}
                      <h5>
                        <a href="#" style={{color:"#333"}}>
                        Body Polishing 
                        </a>
                      </h5>
                    </div>
                    <div className="featured-desc">
                      {/* featured-title */}
                      <p>
                      Revitalize your skin's luminosity at our salon, where body polishing services combine indulgent care and meticulous techniques to reveal a radiant and smooth complexion.
                      </p>
                    </div>
                    <div className="ttm-border-seperator" />
                    {/* <a
                      className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-10"
                      href="#" style={{color:"#333"}} 
                    >
                      Read More <FaAngleDoubleRight className='ti-angle-double-right' style={{color:"#333"}}  />
                    </a> */}
                  </div>
                </div>
              </div>
                  </div>
              </div>
             
             
            </div>
          </div>
        
        </div>
      </div>
      
    </div>
  </div>
</div>
</section>

<div className='container'>
  {/* <p style={{textAlign:"justify"}}>
  Welcome to our Beauty salon in Andheri, where beauty meets expertise in a harmonious blend of services designed for both men and women. Our mission is to create a welcoming and inclusive space, offering a diverse range of beauty services that cater to everyone's unique preferences and needs.
  </p> */}
  <p style={{textAlign:"justify"}}>
  At our Beauty salon in Andheri, we pride ourselves on our team of skilled and passionate professionals who bring creativity and precision to every service. Whether you're looking for a trend-setting haircut, a rejuvenating facial, or a relaxing massage, our experts are dedicated to providing a personalized and memorable experience.
  </p>
  <p style={{textAlign:"justify"}}>
  For hair services, our stylists stay ahead of the latest trends while also embracing timeless classics. From cutting-edge styles to traditional looks, we ensure that every client leaves our salon feeling confident and refreshed. Our color specialists are adept at creating vibrant hues and natural tones, tailored to enhance individual beauty.

  </p>
  <p  style={{textAlign:"justify"}}>
  Indulge in our skincare treatments designed to promote radiant and healthy skin. Our facials address a variety of concerns, from hydration to anti-aging, utilizing top-notch products and techniques. Men and women alike can enjoy the benefits of our manicure and pedicure services, leaving hands and feet impeccably groomed.

  </p>
  <p style={{textAlign:"justify"}}>
  For those seeking relaxation, our massage therapies provide a soothing escape from the hustle and bustle of everyday life. Tailored to your needs, our massages aim to relieve stress and rejuvenate the body, offering a tranquil oasis in the heart of the city.

  </p>
  <p style={{textAlign:"justify"}}>
  We understand the importance of inclusivity, and our salon is committed to providing a comfortable environment for everyone. Our Beauty salon in Andheri approach ensures that all clients receive exceptional service regardless of gender, embracing the diverse beauty of each individual.

  </p>
  <p style={{textAlign:"justify"}}>
  Step into our Beauty salon in Andheri and let us pamper you with our comprehensive beauty services. Whether you're here for a routine touch-up or a transformative makeover, our dedicated team is ready to exceed your expectations and leave you looking and feeling your best.

  </p>
</div>
  </div>   
    </div>
  )
}

export default Beautyservices
