
import './App.css';
import Footer from './components/Footer';
import Hadder from './components/Hadder';
import Aboutus from './pages/Aboutus';
import Contectus from './pages/Contectus';
import Faq from './pages/Faq';
import Home from "./pages/Home"
import Gallery from "./pages/Gallery"
import ServiceWoman from './pages/ServiceWoman';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Services from './pages/Services';
import Beautyservices from './pages/Beautyservices';
import Error from "../src/components/Error"
function App() {
  return (
    <>
          <BrowserRouter> 
      <Hadder/>
      <Routes>

     <Route path='/' element={<Home/>}></Route>
     <Route path='/best-unisex-salon-andheri-west' element={<Aboutus/>}></Route>
     <Route path='/best-unisex-salon-near-andheri' element={<Contectus/>}></Route>

     <Route path='/best-beauty-services-salon-andheri' element={<Gallery/>}></Route>
     <Route path='/top-mens-salon-andheri-west' element={<Services/>}></Route>
     <Route path='/best-hairstyles-salon-andheri' element={<Faq/>}></Route>
     <Route path='/salon-for-females-andheri-west' element={<ServiceWoman/>}> </Route>
     <Route path='/beauty-salon-andheri' element={<Beautyservices/>}>
    
     </Route>
     <Route path='/*' element={<Error/>}></Route>
     {/* {
            Servicedata.map((route, index) => {
              console.log("route.slug",route.slug)
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.slug}
                    element={<Servicedetil/>}
                  />
                </>
              )
            })
          } */}
      </Routes>
<Footer/>
    </BrowserRouter>
  </>
  );
}

export default App;
