import React, { useState } from 'react';
import { MdLocationOn } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import Logo from  "../Asset/LOGO.png"
const Hadder = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleToggle = () => {
    setMenuOpen(!isMenuOpen);
  };
  const [isServicesSubMenuOpen, setServicesSubMenuOpen] = useState(false);

  const handleServicesSubMenuToggle = () => {
    setServicesSubMenuOpen(!isServicesSubMenuOpen);
  };

  return (
    <div>
      <header id="masthead" className="header ttm-header-style-01">
  {/* ttm-header-wrap */}
  <div className="ttm-header-wrap">
    {/* ttm-stickable-header-w */}
    <div
      id="ttm-stickable-header-w"
      className="ttm-stickable-header-w clearfix"
    >
      {/* ttm-topbar-wrapper */}
      <div className="ttm-topbar-wrapper ttm-textcolor-white clearfix">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="ttm-topbar-content">
                <ul className="top-contact text-left">
                  <li>
                    <i className="fa fa-map-marker ttm-textcolor-skincolor" />
                    
                    <MdLocationOn className='fa ttm-textcolor-skincolor' />
                   : Shop No.42 & 43, Evershine ll,
Usha Swapna CHS, Gharkul Society,
Ratan Nagar, Seven Bunglow,
Andheri w, Mumbai, Maharashtra 400053
                  </li>
                  <li>
                    <i className="fa fa-envelope-o ttm-textcolor-skincolor" />
                    <MdEmail className='ttm-textcolor-skincolor' />
                    <a href="infoDiamondsalon.com">: diamondsalon2020@gmail.com</a>
                  </li>
                </ul>
                <div className="topbar-right text-right">
                  <div className="ttm-social-links-wrapper list-inline">
                    <ul className="social-icons">
                      <li>
                        <a
                          href="https://www.facebook.com/people/Diamond-Unisex-Spa-And-Salon/100075844644241/"
                          className="tooltip-bottom"
                          data-tooltip="Facebook"
                          target="_blank"
                        >
                          <FaFacebookF />
                          <i className="fa fa-facebook" />
                        </a>
                      </li>
                   
                     
                      <li>
                        <a
                          href="https://www.instagram.com/diamondsalon8/"
                          className="tooltip-bottom"
                          data-tooltip="Instagram"
                          target="_blank"
                        >
                          <FaInstagram />
                          <i className="fa fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ttm-topbar-wrapper end */}
      <div id="site-header-menu" className="site-header-menu">
        <div className="site-header-menu-inner ttm-stickable-header">
          <div className="container">
            <div className="row">
              <div className="col">
                {/*site-navigation */}
                <div
                  id="site-navigation"
                  className="site-navigation d-flex flex-row"
                >
                  <div className="site-branding mr-auto">
                    {/* site-branding */}
                    <a
                      className="home-link"
                      href="/"
                      title="Sylin Beauty"
                      rel="home"
                    >
                      <img
                        id="logo-img"
                        className="img-center lazyloaded"
                        src={Logo}
                        alt="logo"
                      />
                    </a>
                    {/* site-branding end */}
                  </div>
                  <div className="ttm-menu-toggle" checked={isMenuOpen} onChange={handleToggle}>
                    <input type="checkbox" id="menu-toggle-form" />
                    <label
                      htmlFor="menu-toggle-form"
                      className="ttm-menu-toggle-block"
                    >
<span className="toggle-block toggle-blocks-1 "/>
            <span className="toggle-block toggle-blocks-2" />
            <span className="toggle-block toggle-blocks-3" />
                    </label>
                  </div>
                 {/* <nav id="menu" className={`menu${isMenuOpen ? 'active' : ''}`}> */}
                 <nav id="menu" className={`menu ${isMenuOpen ? 'active' : ''}`}>
                    <ul className="dropdown">
                      <li >
                        <a href="/">Home</a>
                      
                      </li>
                      <li >
                        <a href="/best-unisex-salon-andheri-west">About Us</a>
                      
                      </li>
                      {/* <li className="has-submenu">
                        <a href="#">services</a>
                      
                      </li> */}
                      {/* <li>
                        <a href="/Servicewoman">Services</a>
                      </li> */}
                      <li className="has-submenu">
  <a href="#" onClick={handleServicesSubMenuToggle} >services</a>
  <ul className={`sub-menu ${isServicesSubMenuOpen ? 'active' : ''}`}>
    <li className="">
      <a href="/top-mens-salon-andheri-west" style={{color:"#bf9456"}}>Man Service</a>
    </li>
    <li className="">
      <a href="/salon-for-females-andheri-west" style={{color:"#bf9456"}}>Woman Service</a>
    </li>
    <li className="">
      <a href="/beauty-salon-andheri" style={{color:"#bf9456"}}>Beauty Services</a>
    </li>
    
  
  </ul>
</li>

                      <li >
                        <a href="/best-beauty-services-salon-andheri">Gallery</a>
                   
                      </li>
                      <li>
                        <a href="/best-hairstyles-salon-andheri">Faq</a>
                   
                      </li>
                      <li>
                        <a href="/best-unisex-salon-near-andheri">CONTACT US</a>
                      </li>
                    </ul>
                  </nav>
                </div>
                {/* site-navigation end*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* ttm-stickable-header-w end*/}
  </div>
  {/*ttm-header-wrap end */}
</header>

    </div>
  )
}

export default Hadder
 