import React from 'react'
import { IoLocationSharp } from "react-icons/io5";
import { IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import Logo from "../Asset/LOGO.png"
const Footer = () => {
  return (
    <div>
      <footer className="footer widget-footer clearfix" style={{paddingTop:"80px"}}>
  <div className="first-footer">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-7 col-sm-9 m-auto text-center">
          <div className="footer-logo">
            <img
              id="footer-logo-img"
              className="img-center"
              src={Logo}
              alt=""
            />
          </div>
          
        
        </div>
      </div>
    </div>
  </div>
  <div className="second-footer ttm-textcolor-white">
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 widget-area">
          <div className="widget widget_text clearfix">
            <h3 className="widget-title">About Us</h3>
            <div className="textwidget widget-text">
              <p className="pb-10 res-767-p-0">
              Sparkle with Elegance at Diamond Salon. Unveil your radiance with our expert stylists and luxurious treatments. Elevate your beauty experience with us. Shine Bright, Shine Beautiful at Diamond Salon.
              </p>
             
              
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 widget-area">
          <div className="widget widget_text clearfix">
            <h3 className="widget-title">Our Links</h3>
            <ul className="widget-post ttm-recent-post-list">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
               <a href="/best-unisex-salon-andheri-west">About Us</a>
              </li>
              <li>
                <a href="/best-beauty-services-salon-andheri">Gallery</a>
              </li>
              <li>
               <a href="/best-hairstyles-salon-andheri">Faq</a>
              </li>
              <li>
                <a href="/best-unisex-salon-near-andheri"> Contect Us</a>
              </li>
              
            </ul>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 widget-area">
          <div className="widget flicker_widget clearfix">
            <h3 className="widget-title">Get In Touch</h3>
            <div className="textwidget widget-text">
              <div className="featured-icon-box icon-align-before-content icon-ver_align-top style3">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-sm">
                    <i className="fa fa-map-marker" />
                    <IoLocationSharp />
                  </div>
                </div>
                <div className="featured-content">
                  <div className="featured-desc">
                    <p>
                    Shop No.42 & 43, Evershine ll,<br></br> Usha Swapna CHS, Gharkul Society,<br>
                    </br> Ratan Nagar, Seven Bunglow, <br></br>Andheri w, Mumbai, Maharashtra 400053
                     
                    </p>
                  </div>
                </div>
              </div>
              <div className="featured-icon-box icon-align-before-content icon-ver_align-top style3">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-sm">
                    <i className="fa fa-envelope-o" />
                    <MdEmail />
                  </div>
                </div>
                <div className="featured-content">
                  <div className="featured-desc">
                    <p>
                      <a href="mailto:diamondsalon2020@gmail.com">diamondsalon2020@gmail.com</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="featured-icon-box icon-align-before-content icon-ver_align-top style3">
                <div className="featured-icon">
                  <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-sm">
                    <i className="fa fa-phone" />
                    <IoCall />
                  </div>
                </div>
                <div className="featured-content">
                  <div className="featured-desc">
                    <p>(+91)8828882887 </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="bottom-footer-text">
    <div className="container">
      <div className="row copyright">
        <div className="col-md-9">
          <div className="ttm-textcolor-white">
            <span>
              Copyright © 2024&nbsp;
              <a className="ttm-textcolor-skincolor" href="#">
                Diamond-salon
              </a>{" "}
              by{" "}
              <a href="https://skdm.in/" target="_blank">
              Shree Krishna Digital Marketing
              </a>
            </span>
          </div>
        </div>
        <div className="col-md-3">
          <div className="d-flex flex-row align-items-center justify-content-end social-icons">
            <ul className="social-icons list-inline">
              <li>
                <a
                  href="https://www.facebook.com/preyantechnosys19"
                  className=" tooltip-top"
                  data-tooltip="Facebook"
                  target="_blank"
                >
                  <i className="fa fa-facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/PreyanTechnosys"
                  className=" tooltip-top"
                  data-tooltip="Twitter"
                  target="_blank"
                >
                  <i className="fa fa-twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.flickr.com/photos/166353669@N03/"
                  className=" tooltip-top"
                  data-tooltip="Flickr"
                  target="_blank"
                >
                  <i className="fa fa-flickr" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"
                  className=" tooltip-top"
                  data-tooltip="Linkedin"
                  target="_blank"
                >
                  <i className="fa fa-linkedin" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

    </div>
  )
}

export default Footer
