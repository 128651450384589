import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
// import {  Pagination, Navigation } from 'swiper/modules';
import { RiDoubleQuotesL } from "react-icons/ri";
const Abtestimonial = () => {
  return (
    <div>
          <Swiper
        spaceBetween={30}
        centeredSlides={true}
        // autoplay={{
        //   delay: 2500,
        //   disableOnInteraction: false,
        // }}
        // pagination={{
        //   clickable: true,
        // }}
        // navigation={true}
     
        className="mySwiper"
      >
                <div className="row">
  <div className="col-lg-10 col-md-12 m-auto" style={{paddingBottom:"30px"}}>
        <SwiperSlide> 
        <div
                className="testimonial-slide style2 owl-carousel owl-theme owl-loaded"
                data-item={1}
                data-nav="false"
                data-dots="true"
                data-auto="false"
              >
                <div className="owl-stage-outer">
                  <div
                    className="owl-stage"
                    style={{
                     
                    }}
                  >
                    <div
                      className="owl-item cloned"
                      style={{ marginRight: 0 }}
                    >
                      <div className="testimonials style2">
                        <div className="testimonial-content">
                          <p>
                          Diamond Salon is one of the best salons you will visit. The staff is super careful and polished when it comes to providing service. Please please do visit themm!!!!!
They are amazing!!!
                          </p>
                          <div className="testimonial-avatar">
                            <div className="testimonial-img">
                              <img
                                className="img-fluid rounded-circle"
                                src="https://themetechmount.net/html/sylin/beauty/images/testimonial/01.jpg"
                                alt="testimonial-img"
                              />
                            </div>
                          </div>
                          <div className="testimonial-caption">
                            <h6>John Kennedy</h6>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
         
              </div>
     </SwiperSlide>
     <SwiperSlide> 
        <div
                className="testimonial-slide style2 owl-carousel owl-theme owl-loaded"
                data-item={1}
                data-nav="false"
                data-dots="true"
                data-auto="false"
              >
                <div className="owl-stage-outer">
                  <div
                    className="owl-stage"
                    style={{
                     
                    }}
                  >
                    <div
                      className="owl-item cloned"
                      style={{ marginRight: 0 }}
                    >
                      <div className="testimonials style2">
                        <div className="testimonial-content">
                          <p>
                          Very nice experience by Pooja. Got awesome mani pedi and eyebrows. Must visit salon.
                          </p>
                          <div className="testimonial-avatar">
                            <div className="testimonial-img">
                              <img
                                className="img-fluid rounded-circle"
                                src="https://themetechmount.net/html/sylin/beauty/images/testimonial/01.jpg"
                                alt="testimonial-img"
                              />
                            </div>
                          </div>
                          <div className="testimonial-caption">
                            <h6>Richa Mahra</h6>
                           
                          </div>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
         
              </div>
     </SwiperSlide>
     <SwiperSlide> 
        <div
                className="testimonial-slide style2 owl-carousel owl-theme owl-loaded"
                data-item={1}
                data-nav="false"
                data-dots="true"
                data-auto="false"
              >
                <div className="owl-stage-outer">
                  <div
                    className="owl-stage"
                    style={{
                     
                    }}
                  >
                    <div
                      className="owl-item cloned"
                      style={{ marginRight: 0 }}
                    >
                      <div className="testimonials style2">
                        <div className="testimonial-content">
                          <p>
                          Very pleasant experience at Diamond Salon. Raju is very good- I reckoned him!!
                          </p>
                          <div className="testimonial-avatar">
                            <div className="testimonial-img">
                              <img
                                className="img-fluid rounded-circle"
                                src="https://themetechmount.net/html/sylin/beauty/images/testimonial/01.jpg"
                                alt="testimonial-img"
                              />
                            </div>
                          </div>
                          <div className="testimonial-caption">
                            <h6>leena roka</h6>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
         
              </div>
     </SwiperSlide>
     
       
      
        </div>
</div>
      </Swiper>
  

  
    </div>
  )
}

export default Abtestimonial
